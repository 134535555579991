import React, { useContext, useState, useEffect } from "react";
import { graphql, Link } from "gatsby";
import PropTypes from "prop-types";
import { jsx, Global, css } from "@emotion/core";
import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Layout from "components/layout";
import styled from "@emotion/styled";
import LocalizedLink from "components/localizedLink";
import SEO from "components/seo";
import { colors } from "style/theme";
import { mq } from "utils/helper";
import { rhythm } from "utils/typography";
import { LocaleContext } from "context/locale-context";
import useTranslations from "components/hooks/useTranslations";

function BlogPostTemplate({ data, location }) {
  const siteTitle = data.site.siteMetadata.title;
  const post = data.mdx;
  const locale = useContext(LocaleContext);
  const isRTL = locale === "ar";
  const tr = useTranslations();

  useEffect(() => {
    // This is releated to the workaound in gatsby-browser.js file
    window.scrollTo(0, 0);
  }, []);

  const designSystem = {
    a: styled(Link)({
      color: colors.primary,
      fontWeight: "bold"
    }),
    p: styled.p({
      direction: isRTL ? "rtl" : "ltr",
      color: colors.dark,
      lineHeight: "26px"
    }),
    h1: styled.h1({
      opacity: 0.7
    }),
    h2: styled.h2({
      opacity: 0.7
    }),
    h3: styled.h3({
      opacity: 0.7
    }),
    h4: styled.h4({
      opacity: 0.7
    }),
    h5: styled.h5({
      opacity: 0.7
    }),
    em: styled.em({
      display: "inline-block",
      backgroundColor: colors.bg,
      textAlign: "center",
      width: "100%"
    }),
    blockquote: styled.blockquote({
      lineHeight: "20px",
      fontSize: "20px",
      borderLeft: isRTL || "6px solid black",
      borderRight: isRTL && "6px solid black",
      marginLeft: "1rem",
      direction: isRTL ? "rtl" : "ltr",
      marginRight: "1rem",
      "& p": {
        marginRight: isRTL && "1rem",
        lineHeight: "43px"
      }
    })
  };

  const firstBox = css({
    "ul:first-of-type": {
      direction: isRTL ? "rtl" : "ltr",
      border: `6px solid ${colors.dark}`,
      boxShadow: "-5px 5px 12px 0 rgba(0,0,0,0.25)",
      padding: `${rhythm(1)} ${rhythm(2)}`,
      "& li": {
        listStyle: "none",
        margin: 0,
        "&::before": {
          content: '"•"',
          color: colors.primary,
          display: "inline-block",
          width: "0.8em",
          fontSize: "2em"
        }
      },
      [mq[0]]: {
        paddingLeft: rhythm(1),
        paddingRight: rhythm(1),
        marginLeft: 0
      }
    }
  });

  return (
    <Layout title={siteTitle}>
      <SEO metadata={post.frontmatter} />

      <div
        css={[
          {
            direction: isRTL ? "rtl" : "ltr",
            maxWidth: rhythm(34),
            margin: "auto",
            marginBottom: rhythm(4),
            justifySelf: "center"
          },
          firstBox
        ]}
      >
        <MDXProvider
          components={{
            a: designSystem.a,
            p: designSystem.p,
            h1: designSystem.h1,
            h2: designSystem.h2,
            h3: designSystem.h3,
            h4: designSystem.h4,
            h5: designSystem.h5,
            em: designSystem.em,
            blockquote: designSystem.blockquote
          }}
        >
          <MDXRenderer>{post.body}</MDXRenderer>
        </MDXProvider>
      </div>
    </Layout>
  );
}

BlogPostTemplate.propTypes = {
  data: PropTypes.object
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!, $locale: String!) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(frontmatter: { locale: { eq: $locale }, slug: { eq: $slug } }) {
      id
      tableOfContents(maxDepth: 2)
      body
      frontmatter {
        title
        date
        slug
        locale
      }
    }
  }
`;
